import {RouterModule, Route} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { SetPasswordComponent } from './views/set-password/set-password.component';
import { UserRegisterComponent } from './views/user-register/user-register.component';

import { UserListinComponent } from './views/user-listin/user-listin.component';
import { UserLeadDetailsComponent } from './views/user-lead-details/user-lead-details.component';
import { LeadsListingComponent } from './views/leads-listing/leads-listing.component';
import { LeadsDetailsComponent } from './views/leads-details/leads-details.component';
import { MastercalculatorComponent } from './views/mastercalculator/mastercalculator.component';

import { TeamSettingComponent } from './views/team-setting/team-setting.component';
import { DocTypeMasterComponent } from './views/doc-type-master/doc-type-master.component';


import { DocCategoryMasterComponent } from './views/doc-category-master/doc-category-master.component';
import { CrmStatusComponent } from './views/crm-status/crm-status.component';
import { BusYearComponent } from './views/bus-year/bus-year.component';
import { ProductComponent } from './views/product/product.component';
import { EmpMasterComponent } from './views/emp-master/emp-master.component';
import { BusinessCategoryComponent } from './views/business-category/business-category.component';
import { IndustryComponent } from './views/industry/industry.component';
import { NatureOfBusinessComponent } from './views/nature-of-business/nature-of-business.component';
import { UsersSalariedComponent } from './views/users-salaried/users-salaried.component';
import { SalariedDetailsComponent } from './views/salaried-details/salaried-details.component';
import { SelfEmployedComponent } from './views/self-employed/self-employed.component';
import { SelfEmpLeadsDetailsComponent } from './views/self-emp-leads-details/self-emp-leads-details.component';

import { ProfessionalComponent } from './views/professional/professional.component';
import { ProfessionalDetailsComponent } from './views/professional-details/professional-details.component';

import { UserListingCTWAComponent } from './views/user-listing-ctwa/user-listing-ctwa.component';
import { UserCTWADetailsComponent } from './views/user-ctwadetails/user-ctwadetails.component';

import { LeadListingCTWAComponent } from './views/lead-listing-ctwa/lead-listing-ctwa.component';
import { LeadCTWADetailsComponent } from './views/lead-ctwadetails/lead-ctwadetails.component';

import { QuickDataEntryComponent } from './views/quick-data-entry/quick-data-entry.component';
import { DetailedDataEntryComponent } from './views/detailed-data-entry/detailed-data-entry.component';
import { UpdateUserProfileComponent } from './views/update-user-profile/update-user-profile.component';
import { MeraemiBusinessPartnerComponent } from './views/meraemi-business-partner/meraemi-business-partner.component';
import { BusinessPartnerDetailsComponent } from './views/business-partner-details/business-partner-details.component';




const routes: Route[] = [
    {path: '', pathMatch:'full', redirectTo:'dashboard'},    
    {path: 'register', component: RegisterComponent},
    {path: 'login', component: LoginComponent},
    {path: 'set-password', component: SetPasswordComponent},
    {path: 'reset-password', component: ResetPasswordComponent},
    {path: 'dashboard', component: DashboardComponent,canActivate:[AuthGuard]}, 

    {path: 'user-register', component:   UserRegisterComponent, canActivate:[AuthGuard]}, 
    {path: 'userListing', component:   UserListinComponent, canActivate:[AuthGuard]}, 
    {path: 'UserLeadDetails', component:   UserLeadDetailsComponent, canActivate:[AuthGuard]},
    {path: 'leadsListing', component:   LeadsListingComponent, canActivate:[AuthGuard]}, 
    {path: 'leadsDetails', component:   LeadsDetailsComponent, canActivate:[AuthGuard]}, 
    {path: 'mastercalculator', component:   MastercalculatorComponent, canActivate:[AuthGuard]}, 
   
    {path: 'teamSetting', component:   TeamSettingComponent, canActivate:[AuthGuard]}, 
    {path: 'docTypeMaster', component:   DocTypeMasterComponent, canActivate:[AuthGuard]}, 

    {path: 'docCategoryMaster', component:   DocCategoryMasterComponent, canActivate:[AuthGuard]}, 
    {path: 'crm-status', component:   CrmStatusComponent, canActivate:[AuthGuard]}, 
    {path: 'busYear', component:   BusYearComponent, canActivate:[AuthGuard]},
    {path: 'product', component:   ProductComponent, canActivate:[AuthGuard]},
    {path: 'empMaster', component:   EmpMasterComponent, canActivate:[AuthGuard]},
    {path: 'businessCategory', component:   BusinessCategoryComponent, canActivate:[AuthGuard]},
    {path: 'industry', component:   IndustryComponent, canActivate:[AuthGuard]},
    {path: 'natureOfBusiness', component:   NatureOfBusinessComponent, canActivate:[AuthGuard]},
    {path: 'salaried', component:   UsersSalariedComponent, canActivate:[AuthGuard]},
    {path: 'salaried-details-page', component:   SalariedDetailsComponent, canActivate:[AuthGuard]},
    {path: 'selfEmployed', component:   SelfEmployedComponent, canActivate:[AuthGuard]},
    {path: 'self-Emp-Leads-Details', component:   SelfEmpLeadsDetailsComponent, canActivate:[AuthGuard]},
    {path: 'professional', component:   ProfessionalComponent, canActivate:[AuthGuard]},
    {path: 'professional-details', component:   ProfessionalDetailsComponent, canActivate:[AuthGuard]},
    {path: 'user-listing-ctwa', component:   UserListingCTWAComponent, canActivate:[AuthGuard]},
    {path: 'user-ctwa-details', component:   UserCTWADetailsComponent, canActivate:[AuthGuard]},
    {path: 'ctwa-lead-listing', component:   LeadListingCTWAComponent, canActivate:[AuthGuard]},
    {path: 'ctwa-details', component:   LeadCTWADetailsComponent, canActivate:[AuthGuard]},
    {path: 'quickDataEntry', component:   QuickDataEntryComponent, canActivate:[AuthGuard]},
    {path: 'detailedDataEntry', component:   DetailedDataEntryComponent, canActivate:[AuthGuard]},
    {path: 'updateUserProfile', component:   UpdateUserProfileComponent, canActivate:[AuthGuard]},
    {path: 'business-partner', component:   MeraemiBusinessPartnerComponent, canActivate:[AuthGuard]},
    {path: 'business-partner-details', component:   BusinessPartnerDetailsComponent, canActivate:[AuthGuard]},
   
   
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes,{useHash: true});
