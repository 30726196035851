import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
   
   // *local  token
      //bearer_token:string = "Bearer  da283981-3722-4d6f-ac02-b655ee956cc8";
   //* CRM token
      bearer_token:string = "Bearer 6adb3359-e45a-4ee7-955f-d3ac3801f697";
   //* uat token
     //bearer_token:string = "Bearer 88995457-f41c-4b18-bfb0-fac0cb476e53";
 
  
  constructor() { }
}
    




