import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';
import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
import {  CookieService} from 'ngx-cookie-service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-lead-listing-ctwa',
  templateUrl: './lead-listing-ctwa.component.html',
  styleUrls: ['./lead-listing-ctwa.component.scss']
})
export class LeadListingCTWAComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  @ViewChild("basicModal10", {static: true}) basicModal10: ModalDirective;
  @ViewChild("basicModal11", {static: true}) basicModal11: ModalDirective;
  @ViewChild("basicModal12", {static: true}) basicModal12: ModalDirective;
  @ViewChild("salary", {static: true}) salary: ModalDirective;
  @ViewChild("newuseradd", {static: true}) newuseradd: ModalDirective;
  
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  elementsSource: any = [];
  elements_adid: any = [];
  elementsLoan: any = [];
  elementsalary: any = [];
  elements_stage: any = [];
  elementsdate: any = [];  
  elementscityname: any = [0];
  elements: any = [];
  clear_stagr: any = [];
  filterarray: any = [];
  arrayele: any = [];
  All_ad_id: any = [];
   optionsArray: any = []; 
   messages: any = []; 
  headElements = [
    {
       name:"Date",
       sortBy:"Date",
       width: 150
     },
     {
      name:"Temp Id",
      sortBy:"Temp Id",
      width: 150
    },
     {
       name:"Ad id",
       sortBy:"ad_id"
     },
     
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Email",
       sortBy:"Email"
     },
     {
       name:"Mobile No",
       sortBy:"Mobile No"
     },
     {
      name:"Ctwa Data Type",
      sortBy:"Ctwa Data Type"
    },
     
     {
       name:"Status",
       sortBy:"Status"
     }

   ];
  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string;
  registerForm: FormGroup;
 
  submitted = false;
  from_user_id:number = 0;
  to_user_id: number = 0;
  valuemain: number;
  userid: number;
  activeClass: string;
  startupsLinks: any;
  series: any;
  result: any;
  resultpush: any = [];
  responseArray:any = [];
  optionsSelect: { value: string; label: string; }[];
  optionsStage: { value: string; label: string; }[];
  datafilter: any;
  source: any;
  adid: any;
  sourcename: number;
  ad_id: number;
  loan_amt: number;
  fromLonAmt: any;
  toLonAmt: any;
  stage: any;
  loan_stage: number;
  fromdate: any;
  todate: any;
  loan_date: number;
  city_name: number;
  cityname: any;
  user_city: any;
  activeSource: string;
  activeadid: string;
  activeloan: string;
  activestage: string;
  activelender: string;
  activedate: string;
  activeLocation: string; 
  results: any;
  useid: any;
  messageDiv: any;
  message: any;
  clearid: any;
  usercity: any;
  selectedValue = '1';
  selectedValues = '1';
  lendernamearray: any = [];
  optionslocation: any = []; 
  optionsadid: any = []; 
  citymainarray: any;
  statusArray: any;
  filtered_people: any = [];
  urlstage: string;
  showLoader: boolean;
  lender_name: any;
  iapstage: number=0;
  first_name: string;
  last_name: string;
  fnamelname: string;
  lenderfilter:string;
  loan_lender:any = [0];
  lender_filter: any;
  meraemistage: string;
  usersatge: string;
  CrmStatus: any = [];
  UserStatus: any = [];
  crm_status: any = [];
  user_status: any = [];  
  crmstage:  any;
  userstage : any;
  meraemi_stage :any = [0];
  meraemi_sub_stage:any = [0];
 activeCrmstage:string;
  activeUserstage:string;
 Modaloneidid: number = 0;
 updateuserad: number = 0;
  adupdatebtnEnable: number= 0;
  usernamelist: number = 0;
  usersal: number=0;
  fromSalary: number=0;
  toSalary: number=0;
  activeIncome: string;
  dbuserid:number=0;
  lendername: any;
  crmstages: any;
  userstages: any;
  empcategory: string;
  fbAdid: null;
  constructor( 
      private cryptojsService:CryptojsService,  
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      public cookieService: CookieService,
      private ctwaService:CtwaService,
      
      ) { }

  ngOnInit() {
  
    
    this.ctwaService
    .getWhatsappJourney()
    .subscribe( (result:any) => {
    // console.log(result); return false;
    
      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.Whatsapp.length;  i++) {
          const element = result.Whatsapp[i];
          const adid= element.data[0].ad_id;
      
                const parts = adid.split(":");
                if (parts.length === 2){
                  this.fbAdid=parts[0];
                }else{
                  this.fbAdid=element.data[0].ad_id;
                }
                  //console.log(this.fbAdid);
                            this.elements.push(
                              {
                                created_on	:element.data[0].created_on,
                                temp_id: element.data[0].temp_id,
                                ad_id: this.fbAdid,
                                name:  element.data[0].first_name+' '+element.data[0].last_name, 
                                email	:element.data[0].email,
                                mobileno: element.data[0].mobileno, 
                                signup: element.data[0].signup, 
                           
                                          
                                
                                
                                

                              }
                              );
                              
                                
            }
          //console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
   
    
    
  }
    //intraktUrlAPIlisting
   
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
 
 
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
 
 








        
     
     //user Details
       getLeadDetails(user){
       
        //console.log(user); return false;
     
         if(user.usersatge !=undefined){
          let userstage=user.usersatge;

           this.useid = this.cryptojsService.encryptData((user.temp_id));       
           const base_url=location.origin+"/#/ctwa-details?data=";        
           const detailsurl = base_url + this.useid.toString();
   
           const dateur=detailsurl+"&userstage="+userstage.toString();
          
           //console.log(detailsurl);
           window.open(dateur, "_blank");
         }else{
         // console.log(user.usersatge);
          this.useid = this.cryptojsService.encryptData((user.temp_id));       
          const base_url=location.origin+"/#/ctwa-details?data=";        
          const detailsurl = base_url + this.useid.toString();
  
        
         
          //console.log(detailsurl);
          window.open(detailsurl, "_blank");
         }
   
       }
        //intraktUrlAPIlisting
        intraktuserlisting(data){
         // console.log(data); return false;
       
           
           const base_url="https://app.interakt.ai/inbox?channelPhoneNumber=";        
          
  
          const dateur=base_url+"91"+data.toString();
         
          // //console.log(detailsurl);
           window.open(dateur, "_blank");
           
     
         }
  
         exportTojson() {
          // exportData is your array which you want to dowanload as json and sample.json is your file name, customize the below lines as per your need.
          let exportData = this.elements;
          return saveAs(
            new Blob([JSON.stringify(exportData, null, 2)], { type: 'JSON' }), 'sample.json'
          );}


           //user Details
 QuickDataEntry(){
  // alert("ok");
        
  const base_url=location.origin+"/#/quickDataEntry";        
  this.newuseradd.hide();

 
 
  //console.log(detailsurl);
  window.open(base_url, "_blank");
   //console.log(user); return false;
   




   

  }
  updateprofile(QuickDataEntry){
    
    this.cookieService.set('detailedDataEntry', JSON.stringify(QuickDataEntry));
    console.log(QuickDataEntry);
    const base_url=location.origin+"/#/updateUserProfile";        
    this.newuseradd.hide();
  
   
   
    //console.log(detailsurl);
    window.open(base_url, "_blank");
     //console.log(user); return false;
  }
  detailedDataEntry(){
   
    const base_url=location.origin+"/#/detailedDataEntry";        
    this.newuseradd.hide();
  
   
   
    //console.log(detailsurl);
    window.open(base_url, "_blank");
     //console.log(user); return false;
  }
}